import React from 'react';
import { FaGithub } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

// Example project data
const projects = [
  {
    name: 'Lab Cheating Surveillance Software',
    description: 'A Programming Lab Cheating Surveillance Software That Uses Keystroke/Mouse/Webcam Sequences To Detect Possible Cheating Scenarios In a Lab Exam Setting Using MT-Cascaded Nueral Networks, Reinforcement Learning Agents and Deep Q-Networks.',
    github: 'https://github.com/ahadazizjaffer2/Cheating-Surveillance' // Replace with actual GitHub URL
  },
  {
    name: 'Friend-Chat App',
    description: 'This Real-Time Chat Application is a web-based platform that facilitates real-time communication between users in designated chat rooms. It includes features such as user authentication, room creation and management, and real-time messaging using Socket-IO Library.',
    github: 'https://github.com/ahadazizjaffer2/FriendChat-App' // Replace with actual GitHub URL
  },
  {
    name: 'Hospital Management System',
    description: 'The HMS built with HTML, CSS, Node.js, and SQL offers user-friendly dashboards for admins, patients, and doctors. It supports appointment scheduling, medical record access, and streamlined healthcare management through a robust and dynamic web application.',
    github: 'https://github.com/ahadazizjaffer2/Hospital-Management-System' // Replace with actual GitHub URL
  },
  {
    name: 'Zakat Calculator App',
    description: 'The Zakat Calculator app, built in C, estimates zakat obligations and records user payments with unique IDs for verification. It uses file handling, functions, structures, and string operations to manage and store data efficiently.',
    github: 'https://github.com/ahadazizjaffer2/Zakat-Calculator' // Replace with actual GitHub URL
  }
];

const Projects = () => {

  const TabletandAbove = ({ children }) => {
    const isTabletandBelow = useMediaQuery({ minWidth: 600 })
    return isTabletandBelow ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 700 })
    return isMobile ? children : null
  }
  return (
    <div className="page">
    <div className="projects">
      <h1>My Projects</h1>
      <TabletandAbove>
        <ul>
          {projects.map((project, index) => (
            <li key={index} className="project-card">
              <h2>{project.name}</h2>
              <p>{project.description}</p>
              <a href={project.github} target="_blank" rel="noopener noreferrer">
                <FaGithub className="github-icon" />
              </a>
            </li>
          ))}
        </ul>
      </TabletandAbove>
      <Mobile>
      <ul>
          {projects.map((project, index) => (
            <li key={index} className="project-card">
              <div style={{ display:'flex', justifyContent: 'space-between', gap: '20px' }}>
                <h2>{project.name}</h2>
                <a href={project.github} target="_blank" rel="noopener noreferrer">
                  <FaGithub className="github-icon" />
                </a>
              </div>
              <p>{project.description}</p>
            </li>
          ))}
        </ul>
      </Mobile>


    </div>
  </div>
  );
};
  
export default Projects;
