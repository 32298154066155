// import React, { useState } from 'react';

// const Hamburger = ({ onClick }) => {
//     const [isOpen, setIsOpen] = useState(false);

//     const handleClick = () => {
//         setIsOpen(prevState => !prevState);
//         if (onClick) {
//             onClick(); 
//         }
//     };

//     return (
//         <div>
//             <button
//                 onClick={handleClick}
//                 className="menubutton"
//             >
//                 <span className={`burger ${isOpen ? 'is-closed' : ''}`}>
//                     <span className="line"></span>
//                     <span className="line"></span>
//                     <span className="line"></span>
//                 </span>
//             </button>
//         </div>
//     );
// };

// export default Hamburger;


import React from 'react';

const Hamburger = ({ isOpen, onClick }) => {
    return (
        <div>
            <button
                onClick={onClick}
                className="menubutton"
            >
                <span className={`burger ${isOpen ? 'is-closed' : ''}`}>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </span>
            </button>
        </div>
    );
};

export default Hamburger;

