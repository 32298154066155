import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive'

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ message: '', type: '', visible: false });

  const showAlert = (message) => {
    setAlert({ message, visible: true });
    setTimeout(() => {
      setAlert({ message: '', type: '', visible: false });
    }, 5000);
  };  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch('https://profilebe.azurewebsites.net/api/contact/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        const errorResult = await response.json();
        console.error('Error response:', errorResult);
        throw new Error(`Network response was not ok: ${errorResult.message}`);
      }
  
      const result = await response.json(); // Expect JSON response
      console.log('Response:', result);

      showAlert('Form submitted successfully!', 'success');
    } catch (error) {
      console.error("There was an error!", error);
      showAlert(`Error: Couldn't send. Please try again`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const isDesktop = useMediaQuery({ minWidth: 1000 });
  const isTabletandBelow = useMediaQuery({ query: '(max-width: 1000px)' });
  const isTablet = useMediaQuery({ minWidth: 700, maxWidth: 1000 });

  return (
    <div>
      {alert.visible && (
        <div className="alert">
          {alert.message}
        </div>
      )}

      {isDesktop && (
        <div className="page">
          <div className="contact">
            <div className="contacthero">
            <div className="contact-info">
              <h1>Contact Me</h1>
              <p>Feel free to reach out if you have any questions or if you’d like to discuss how I can assist you.</p>

              {/* Contact Details and Social Links */}
              <div className="contact-links">
                <p>Email: <a href="mailto:ahadaziz4@gmail.com">ahadaziz4@gmail.com</a>
                <br />LinkedIn: <a href="https://linkedin.com/in/ahad-aziz" target="_blank" rel="noopener noreferrer">LinkedIn Profile</a>
                <br />GitHub: <a href="https://github.com/yourprofile" target="_blank" rel="noopener noreferrer">GitHub Profile</a></p>
              </div>
            </div>
            <img src="sticker.png" alt="" style={{ transform: 'scaleX(-1)', height:'30vw' }}  />
            </div>

            <p>Or fill out the form below and I will get back to you:</p>

            <form onSubmit={handleSubmit} className="contact-form">
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
              </div>
              <button type="submit" disabled={isLoading}>Submit</button>
            </form>
          </div>
        </div>
      )}

      {isTabletandBelow && (
        <div className="page">
          <div className="contact">
            <div className="contactmobhero">
              <h1>Contact Me</h1>
              <p>Feel free to reach out if you have any questions or if you’d like to discuss how I can assist you.</p>
              <div className="contactmob-info">
                  
                <div className="contact-links">
                  <p>Email: <a href="mailto:ahadaziz4@gmail.com">ahadaziz4@gmail.com</a>
                  <br />LinkedIn: <a href="https://linkedin.com/in/ahadaziz" target="_blank" rel="noopener noreferrer">ahadaziz</a>
                  <br />GitHub: <a href="https://github.com/ahadazizjaffer2" target="_blank" rel="noopener noreferrer">ahadazizjaffer2</a></p>
                </div>
                {isTablet && (
                <img src="envelope greeting.png" alt="" style={{ transform: 'scaleX(-1)', height:'20vw' }}  />
                )} 
              
              </div>
            
            </div>
            <p>Or fill out the form below and I will get back to you:</p>

            <form onSubmit={handleSubmit} className="contact-form">
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
              </div>
              <button type="submit" disabled={isLoading}>Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
