// src/components/Footer.js
import React from 'react';

const Footer = () => (
  <footer>
    <span>&copy; {new Date().getFullYear()} Ahad Aziz. All rights reserved.</span>
  </footer>
);

export default Footer;
