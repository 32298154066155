const Bars = () => {
    return (
      <div id="bars" className="bars">
        <div />
        <div style={{ animationDelay: "0.1s" }} />
        <div style={{ animationDelay: "0.2s" }} />
        <div style={{ animationDelay: "0.3s" }} />
        <div style={{ animationDelay: "0.4s" }} />
      </div>
    );
};
  
export default Bars;
  