import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Link from "./Link";
import useTheme from "../hooks/usetheme";
import ThemeToggleButton from "./ThemeSwitcher";
import Hamburger from './Hamburger';

const Navigation = () => {
    const { theme, toggleTheme } = useTheme();
    const [isNavOpen, setIsNavOpen] = useState(false);

    const handleHamburgerClick = () => {
        setIsNavOpen(prevState => !prevState);
    };

    const TabletandAbove = ({ children }) => {
        const isTabletandBelow = useMediaQuery({ minWidth: 600 })
        return isTabletandBelow ? children : null
    }
    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 600 });

        useEffect(() => {
            const ulElement = document.querySelector('ul');
            if (ulElement) {
              if (isMobile) {
                if (isNavOpen) {
                  ulElement.classList.remove('ul-slide-exit');
                  ulElement.classList.add('ul-slide-enter');
                } else {
                  ulElement.classList.remove('ul-slide-enter');
                  ulElement.classList.add('ul-slide-exit');
                }
              } else {
                // Optional: Reset classes when not on mobile
                ulElement.classList.remove('ul-slide-enter', 'ul-slide-exit');
              }
            }
          }, [isMobile]); // Add isMobile to dependencies

        return isMobile ? children : null
      }

    return (
        <>
            <TabletandAbove>
                <nav>
                    <h1>&lt;AAJ/&gt;</h1>
                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/projects">Projects</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                        <li>
                            <ThemeToggleButton 
                                isDark={theme === "dark"} 
                                onChange={toggleTheme} 
                                invertedIconLogic={false} 
                            />
                        </li>
                    </ul>
                </nav>
            </TabletandAbove>
            <Mobile>
            <nav style={{ padding: '0 20px' }}>
                <h1>&lt;AAJ/&gt;</h1>
                <Hamburger onClick={handleHamburgerClick} 
                isOpen={isNavOpen} 
                />
            </nav>
            <ul className='mobilemenu'
                style={{
                    flexDirection: 'column',
                    backgroundColor: theme === "dark" ? '#1e1f21' : '#fff',
                    width: '100%',
                    padding:'20px 0',
                    left: '0',
                    // display: isNavOpen ? 'flex' : 'none', // Toggle display based on isNavOpen
                }}
            >
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/projects">Projects</Link>
                </li>
                <li>
                    <Link to="/contact">Contact</Link>
                </li>
                <li>
                    <ThemeToggleButton 
                        isDark={theme === "dark"} 
                        onChange={toggleTheme} 
                        invertedIconLogic={false} 
                    />
                </li>
            </ul>
            </Mobile>
        
        </>
    );
};

export default Navigation;
