import { useNavigate, useLocation } from "react-router-dom";

const Link = ({ to, children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClicked = () => {

    if (location.pathname === to) {
      return; // Do nothing if the link is the same as the current path
    }

    const bars = document.getElementById("bars");

    bars?.classList.add("show");

    setTimeout(() => {
      bars?.classList.remove("show");
      bars?.classList.add("hide");
      navigate(to);
    }, 800);

    setTimeout(() => bars?.classList.remove("hide"), 1600);
  };

  return (
    <a className={location.pathname === to ? "active" : ""}
    onClick={handleClicked} 
  >
    {children}
  </a> 
  );
};

export default Link;
