import { Outlet } from "react-router-dom";
import Bars from "./Bars";
import Navigation from "./Navigation";
import Footer from "./Footer";

const Layout = () => {

  

  return (
   <>
      <Navigation />

      <Bars />
      <main>
        <Outlet />
      </main>

      <Footer />
    </>
  );
};

export default Layout;
